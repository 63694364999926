/* eslint-disable no-dupe-keys */
import React from "react";
import Box from "@src/components/mui/box";
import CircularProgress from "@src/components/mui/circular-progress";

const Loader = () => {
  return (
    <Box
      sx={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        transform: "-webkit-translate(-50%, -50%)",
        transform: " -moz-translate(-50%, -50%)",
        transform: "-ms-translate(-50%, -50%)",
        zIndex: "9999",
      }}
    >
      <CircularProgress />
    </Box>
  );
};
export default Loader;
