import React, { lazy, Suspense } from "react";
import "@src/fonts/helvetica-neu/index.css";
import "!file-loader?name=[name].[ext]!./assets/images/favicon.ico";
import Loader from "@src/projects/rm-terminal/widgets/loader";

const LazyRmTerminalApp = lazy(() => import("@src/projects/rm-terminal"));

const LazyClientApp = lazy(() => import("@src/projects/client"));

export default function App() {
  const shouldRenderClientApp = /^\/client(?!-)/.test(window.location.pathname);
  return (
    <React.StrictMode>
      <Suspense fallback={<Loader />}>
        {shouldRenderClientApp ? <LazyClientApp /> : <LazyRmTerminalApp />}
      </Suspense>
    </React.StrictMode>
  );
}
